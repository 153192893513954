<template>

  <div>

    <div class="container-lg">

      <div class="auth-container">
        <div class="auth-form">

          <h2>Verify your email address</h2>
          <p>Enter the 6-digit code from the email we just sent to <strong></strong></p>
          <div id="verification_resend_alert" class="alert alert-primary alert-dismissible" role="alert">
            A new code has been sent to your email address
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>

          <form name="new_account_form" id="new_account_form" action="dashboard-new-user.html" method="post" type="form" role="form">
            <div class="mb-3">
              <label class="form-label" for="user_email">Verification code <span class="required">*</span></label>
              <input type="text" class="form-control" id="verification_code" name="verification_code" required />
            </div>

            <div class="mb-3">
              <router-link to="/dashboard"><button type="submit" class="custom-btn-lg custom-btn-secondary custom-btn-full custom-btn-rounded">Verify</button></router-link>
            </div>
          </form>

          <div class="login-link-small">Didn’t receive a code? <a id="resend_code" href="#">Resend</a></div>

        </div><!-- End auth-form -->
      </div><!-- End auth-container -->

    </div><!-- End container-lg -->

  </div>
</template>

<script>
export default {
  name: "verification.vue",
     computed: {
        auth() {
            return JSON.parse(localStorage.getItem(process.env.VUE_APP_NAME + '_user'))
        }
    },
}
</script>

<style scoped>
</style>